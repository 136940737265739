<template>
  <div class="config-container">
    <div class="header">
      <div class="back" @click="goBack">
        <div class="back-icon"></div>
      </div>
      <div class="title">发红包</div>
    </div>
    <div class="bag-config">
      <div class="bag-type">{{ form.type === 'ordinary' ? '普通红包' : '拼手气红包' }}</div>
      <div class="bag-input" @click="selectLuckyAmount">
        <div class="input-label">{{ form.type === 'ordinary' ? '单个金额' : '红包总金额' }}</div>
        <div class="input-box">
          <Field v-if="form.type === 'ordinary'" ref="amount-input" v-model="form.amount" input-align="right" type="number" placeholder="0.00" maxlength="8" :formatter="inputFormatter" style="padding: 16px" />
          <!-- <input v-if="form.type === 'ordinary'" v-model="form.amount" class="input-element" placeholder="0.00" />
          <div v-else class="amount-num">{{ toggleAmount }}</div> -->
          <div v-else class="amount-num">{{ toggleAmount }}</div>
          <div class="box-content">
            <div class="counting">元</div>
            <div v-if="form.type === 'lucky'" class="arrow-bottom"></div>
          </div>
        </div>
      </div>
      <div class="bag-tips">
        <span v-if="form.type === 'ordinary'" class="tips-text">红包金额上限20000，至少1元 </span>
        <span class="tips-orange" @click="switchRedType">{{ form.type === 'ordinary' ? '改为拼手气红包' : '改为普通红包' }}</span>
      </div>
      <div
        class="bag-input num"
        @click="
          () => {
            if (form.type === 'ordinary') $refs['count-input'].focus();
          }
        "
      >
        <div class="input-label">红包个数</div>
        <div class="input-box">
          <Field v-if="form.type === 'ordinary'" ref="count-input" v-model="form.count" input-align="right" type="digit" placeholder="填写个数" maxlength="3" :formatter="inputFormatterCount" style="padding: 0.4rem" />
          <div v-else class="amount-num">{{ toggleNum }}</div>
          <div class="box-content">
            <div class="counting">个</div>
          </div>
        </div>
      </div>
      <div class="tips-more">
        <div class="tips-text">{{ form.type === 'ordinary' ? '红包个数上限500个' : '' }}</div>
        <div
          :class="{
            more: true,
            on: isOpenConfig,
          }"
          @click="openConfig"
        >
          <div class="more-text">更多设置</div>
          <div class="more-icon"></div>
        </div>
      </div>
      <div :class="{ 'more-config': true, on: isOpenConfig }">
        <div
          class="bag-input num"
          @click="
            () => {
              $refs['countdown-min'].focus();
            }
          "
        >
          <div class="input-label">倒计时</div>
          <div class="input-box">
            <!-- <input v-model="form.countdown_min" class="input-element" /> -->
            <Field
              ref="countdown-min"
              v-model="form.countdown_min"
              input-align="right"
              type="digit"
              placeholder=""
              maxlength="3"
              style="width: 3rem; padding: 0.4rem"
              :formatter="
                (val) => {
                  if (val > 700) {
                    $toast('倒计时最多设置700分钟！');
                    return 700;
                  }
                  if (!val && !val.length) {
                    return '';
                  }
                  return Number(val);
                }
              "
            />
            <div class="box-content">
              <div class="counting">分钟</div>
            </div>
          </div>
        </div>
        <div
          class="bag-input"
          @click="
            () => {
              $refs['level'].focus();
            }
          "
        >
          <div class="input-label">角色等级</div>
          <div class="input-box">
            <div class="greater"></div>
            <!-- <input v-model="form.level" class="input-element grade" /> -->
            <Field
              ref="level"
              v-model="form.level"
              input-align="right"
              type="digit"
              placeholder="1"
              maxlength="4"
              style="width: 0.9rem; padding: 0.4rem 0.08rem"
              :formatter="
                (val) => {
                  if (val > 1000) {
                    $toast('等级最高设置1000级！');
                    return 1000;
                  }
                  if (!val && !val.length) {
                    return '';
                  }
                  if (val < 1) {
                    $toast('等级最低设置1级！');
                    return 1;
                  }
                  return Number(val);
                }
              "
            />
            <div class="box-content">
              <div class="counting">级</div>
            </div>
          </div>
        </div>
        <div
          class="bag-input"
          @click="
            () => {
              $refs['create-role'].focus();
            }
          "
        >
          <div class="input-label">首次创角时间</div>
          <div class="input-box">
            <!-- <input v-model="form.first_role_create_hours" class="input-element" /> -->
            <Field
              ref="create-role"
              v-model="form.first_role_create_hours"
              input-align="right"
              type="digit"
              placeholder="0"
              maxlength="3"
              style="width: 3rem; padding: 0.4rem"
              :formatter="
                (val) => {
                  if (val > 999) {
                    $toast('创角时间最长设置999小时！');
                    return 999;
                  }
                  if (!val && !val.length) {
                    return '';
                  }
                  return Number(val);
                }
              "
            />
            <div class="box-content">
              <div class="counting">小时内</div>
            </div>
          </div>
        </div>
        <div class="create-tips">0小时为无创角时间限制</div>
      </div>
      <div class="total-money">
        <div class="symbol">¥</div>
        <div class="number">{{ form.type === 'ordinary' ? ordinaryToggleAmount : toggleAmount || 0 }}</div>
      </div>
      <div class="button-box">
        <div
          :class="{
            button: true,
            on: (form.type === 'ordinary' && Number(form.amount) && Number(form.count)) || (form.type === 'lucky' && toggleAmount),
          }"
          @click="createRedBag"
        >
          塞钱进红包
        </div>
      </div>
    </div>
    <div v-if="isShowPay" class="mask"></div>
    <div v-if="isShowPay" class="dialog">
      <img :src="qrCode" class="qr-code" />
      <div class="qr-tips">打开微信扫一扫，扫描二维码进行支付</div>
      <div class="close-dialog" @click="closePayDialog"></div>
    </div>
    <Popup v-model="showPicker" round position="bottom">
      <Picker title="" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel" @change="onChange" />
    </Popup>
  </div>
</template>

<script>
import { createGrabRedBag, getRedBagOption, getRedBagCreateInfo } from '@/api/redBag';
import { Picker, Popup, Field } from 'vant';
import { getDeviceType, myToFixed } from '@/utils';
export default {
  name: 'ConfigRed',
  components: {
    Picker,
    Popup,
    Field,
  },
  props: {
    activity: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      app_id: window.sessionStorage.getItem('appid'),
      token: window.sessionStorage.getItem('ato'),
      form: {
        type: 'lucky', //红包类型 lucky 拼手气  ordinary 普通
        amount: null, //普通红包必传 金额
        count: null, //普通红包必传 个数
        lucky_config_id: '', //拼手气红包必传
        // start_at_ts: '', //开始时间戳
        countdown_min: 0, //倒计时分钟
        level: 1, //条件等级
        first_role_create_hours: 0, //首次创角N小时
      },
      isOpenConfig: true,
      isShowPay: false,
      columns: [],
      lucky_option: [],
      showPicker: false,
      toggleAmount: '',
      toggleNum: '',
      qrCode: '',
      timer: null,
      isTimer: false,
      isCreateRedBag: false,
    };
  },
  computed: {
    ordinaryToggleAmount() {
      let Amount = 0;
      if (this.form.amount && this.form.amount.indexOf('.') > -1) {
        //有小数点
        //修正字符串精度,保证位数正确,然后把两位小数改成整数进行运算,最后结果除以100
        const str = myToFixed(this.form.amount, 2).replace('.', '');
        console.log(str, this.form.count);
        Amount = (Number(str) * Number(this.form.count)) / 100;
      } else {
        Amount = Number(this.form.amount) * Number(this.form.count);
      }
      return Amount ? Amount : 0;
    },
  },
  mounted() {},
  activated() {
    // this.getOptionsList();
    //每次进入重置页面
    this.isShowPay = false;
    this.timer = null;
    this.isTimer = true; //关闭定时器
    clearTimeout(this.timer);
    Object.assign(this.form, {
      type: 'lucky', //红包类型 lucky 拼手气  ordinary 普通
      amount: null, //普通红包必传 金额
      count: null, //普通红包必传 个数
      lucky_config_id: '', //拼手气红包必传
      // start_at_ts: '', //开始时间戳
      countdown_min: 0, //倒计时分钟
      level: 1, //条件等级
      first_role_create_hours: 0, //首次创角N小时
    });
    this.getOptionsList();
  },
  methods: {
    inputFormatter(val) {
      if (Number(val) > 20000) {
        this.$toast('金额最高20000！');
        return '20000';
      }
      if (!val && !val.length) {
        return '';
      }
      if (Number(val) < 1) {
        return 1;
      }
      console.log(val.match(/^\d+(?:\.\d{0,2})?/)[0]);
      return val.match(/^\d+(?:\.\d{0,2})?/)[0];
    },
    inputFormatterCount(val) {
      if (val > 500) {
        this.$toast('红包个数最多500个！');
        return 500;
      }
      if (!Number(val)) {
        return '';
      }
      return Number(val);
    },
    closePayDialog() {
      this.isShowPay = false;
      this.timer = null;
      if (this.isTimer) {
        this.$router.push({ name: 'GrabRed' });
      }
      this.isTimer = true; //关闭定时器
      clearTimeout(this.timer);
    },
    selectLuckyAmount() {
      if (this.form.type === 'ordinary') {
        this.$refs['amount-input'].focus();
        return;
      }
      this.showPicker = true;
    },
    getOptionsList() {
      getRedBagOption({ app_id: this.app_id, token: this.token, os: getDeviceType(), channel_id: window.sessionStorage.getItem('channel_id'), pf: window.sessionStorage.getItem('pf') }).then((res) => {
        console.log('拼手气红包下拉配置数据', res);
        this.columns.splice(0, this.columns.length, ...res.data.data.lucky_option.map((e) => e.amount_total));
        this.lucky_option = res.data.data.lucky_option;
        //默认选择第一条
        this.form.lucky_config_id = this.lucky_option[0].id;
        this.toggleAmount = this.lucky_option[0].amount_total;
        this.toggleNum = this.lucky_option[0].count;
      });
    },
    onConfirm(value, index) {
      const itemIndex = this.lucky_option.findIndex((e) => value === e.amount_total);
      // console.log('itemIndex', this.lucky_option, itemIndex);
      if (itemIndex > -1) {
        this.form.lucky_config_id = this.lucky_option[itemIndex].id;
        this.toggleAmount = this.lucky_option[itemIndex].amount_total;
        this.toggleNum = this.lucky_option[itemIndex].count;
        this.showPicker = false;
      }
    },
    onChange(picker, value, index) {},
    onCancel() {
      // this.$toast('取消');
      this.showPicker = false;
    },
    createRedBag() {
      if (this.isCreateRedBag) {
        return;
      }
      //普通红包判空
      if (this.form.type === 'ordinary' && (!Number(this.form.amount) || !Number(this.form.count))) {
        this.$toast('红包金额或者红包个数不能为空！');
        return;
      }
      //拼手气红包判空
      if (this.form.type === 'lucky' && !this.toggleAmount) {
        this.$toast('请选择拼手气红包的金额！');
        return;
      }
      const params = Object.assign(
        {},
        {
          app_id: this.app_id,
          token: this.token,
          os: getDeviceType(),
          channel_id: window.sessionStorage.getItem('channel_id'),
          pf: window.sessionStorage.getItem('pf'),
        },
        this.form,
      );
      if (this.form.type === 'ordinary') {
        delete params.lucky_config_id;
      }
      if (this.form.type === 'lucky') {
        delete params.amount;
        delete params.count;
      }

      if (!params.countdown_min) {
        params.countdown_min = 0;
      }
      if (!params.level) {
        params.level = 1;
      }
      if (!params.first_role_create_hours) {
        params.first_role_create_hours = 0;
      }

      this.isCreateRedBag = true;
      createGrabRedBag(params).then((res) => {
        console.log('提交发红包配置结果', res);
        if (res.data.status === 200) {
          this.qrCode = res.data.data.base64;
          this.isShowPay = true;
          this.isTimer = false; //打开定时器限制
          this.setTimer(res.data.data.id);
        } else {
          this.$toast(res.data.msg);
        }
        this.isCreateRedBag = false;
      });
    },
    //轮询支付结果
    setTimer(redbag_id) {
      if (this.isTimer) return;
      this.timer = setTimeout(() => {
        getRedBagCreateInfo({
          app_id: this.app_id,
          redbag_id,
          token: this.token,
          os: getDeviceType(),
          channel_id: window.sessionStorage.getItem('channel_id'),
          pf: window.sessionStorage.getItem('pf'),
        }).then((result) => {
          console.log('红包支付结果', result);
          if (this.isTimer) return;
          if (result.data.status === 200) {
            clearTimeout(this.timer);
            this.timer = null;
            if (!result.data.data.is_pay) {
              //未支付成功继续查询
              this.setTimer(redbag_id);
            } else {
              //支付成功,关闭定时器绑定

              this.isTimer = true;
              this.$router.push({ name: 'GrabRed' });
            }
          }
        });
      }, 1000);
    },
    switchRedType() {
      if (this.form.type === 'ordinary') {
        this.form.type = 'lucky';
      } else {
        this.form.type = 'ordinary';
      }
    },
    goBack() {
      this.isTimer = true; //关闭定时器
      clearTimeout(this.timer);
      this.timer = null;
      this.$router.go(-1);
    },
    openConfig() {
      this.isOpenConfig = !this.isOpenConfig;
    },
  },
};
</script>

<style lang="less" scoped>
.config-container {
  background-color: #f7f7f7;
  min-height: 100%;
  min-height: 100vh;
  .header {
    position: relative;
    .back {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 0.36rem;
      .back-icon {
        width: 0.2rem;
        height: 0.29rem;
        background-image: url('../../assets/imgs/back.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .title {
      text-align: center;
      font-size: 0.34rem;
      font-weight: 500;
      color: #222222;
      line-height: 1.35rem;
    }
  }
  .bag-config {
    margin-top: 0.18rem;
    padding: 0 0.32rem;
    .bag-type {
      font-size: 0.28rem;
      font-weight: 500;
      color: #e99732;
      line-height: 0.32rem;
      padding: 0 0.36rem;
    }
    .bag-input {
      margin-top: 0.3rem;
      background-color: #fefefe;
      box-shadow: 0px 0.02rem 0.01rem 0px rgba(200, 200, 200, 0.1);
      border-radius: 0.05rem;
      padding: 0 0.36rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1rem;
      &.num {
        margin-top: 0.46rem;
      }
      .input-label {
        font-size: 0.28rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.4rem;
      }

      .input-box {
        display: flex;
        align-items: center;
        max-width: 4rem;
        height: 1.03rem;
        overflow: hidden;
        .greater {
          width: 0.17rem;
          height: 0.19rem;
          background-image: url('../../assets/imgs/greater.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
        .input-element {
          margin-right: 0.18rem;
          text-align: right;
          border: none;
          outline: none;
          // background-color: #ccc;
          line-height: 1rem;
          // width: 1.18rem;
          width: 3rem;
          &::-webkit-input-placeholder,
          textarea::-webkit-input-placeholder {
            color: #c4c4c4;
            font-size: 0.28rem;
          }

          &:-moz-placeholder,
          textarea:-moz-placeholder {
            color: #c4c4c4;
            font-size: 0.28rem;
          }

          &::-moz-placeholder,
          textarea::-moz-placeholder {
            color: #c4c4c4;
            font-size: 0.28rem;
          }

          &:-ms-input-placeholder,
          textarea:-ms-input-placeholder {
            color: #c4c4c4;
            font-size: 0.28rem;
          }
          &.grade {
            margin-left: 0.18rem;
            width: 0.6rem;
          }
        }
        .amount-num {
          font-size: 0.28rem;
          margin-right: 0.18rem;
        }
        .box-content {
          display: flex;
          align-items: center;
          line-height: 1rem;
          .counting {
            margin-right: 0.12rem;
            font-size: 0.28rem;
            font-weight: 500;
            color: #222222;
          }
          .arrow-bottom {
            width: 0.2rem;
            height: 0.12rem;
            background-image: url('../../assets/imgs/arrow-bottom.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }
    .bag-tips {
      margin-top: 0.2rem;
      font-size: 0.26rem;
      font-weight: 400;
      color: #909090;
      line-height: 0.4rem;
      padding: 0 0.35rem;
      .tips-text {
      }
      .tips-orange {
        color: #ff7d26;
      }
    }
    .tips-more {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.22rem;
      padding: 0 0.35rem;
      .tips-text {
        font-size: 0.26rem;
        font-weight: 400;
        color: #909090;
        line-height: 0.4rem;
      }
      .more {
        display: flex;
        align-items: center;

        .more-text {
          font-size: 0.26rem;
          font-weight: 400;
          color: #222222;
          line-height: 0.4rem;
          margin-right: 0.1rem;
        }
        .more-icon {
          width: 0.2rem;
          height: 0.12rem;
          background-image: url('../../assets/imgs/more-icon.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
          transition: transform 0.25s;
        }
        &.on {
          .more-icon {
            transform: rotateZ(180deg);
          }
        }
      }
    }
    .more-config {
      overflow: hidden;
      height: 4.64rem;
      transition: height 0.25s;
      &.on {
        height: 0;
      }
      .create-tips {
        font-size: 0.26rem;
        font-weight: 400;
        color: #909090;
        line-height: 0.4rem;
        padding: 0 0.35rem;
        margin-top: 0.18rem;
      }
    }
    .total-money {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.44rem;
      margin-top: 0.45rem;
      .symbol {
        font-size: 0.6rem;
        font-weight: 800;
        color: #222222;
        line-height: 0.26rem;
      }
      .number {
        font-size: 0.7rem;
        font-weight: bold;
        color: #222222;
        // line-height: 0.34rem;
      }
    }
    .button-box {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 1.5rem;
      .button {
        width: 3.5rem;
        line-height: 0.9rem;
        background-color: #ecb8ae;
        border-radius: 0.08rem;
        text-align: center;
        font-size: 0.36rem;
        font-weight: 400;
        color: #ffffff;
        &.on {
          background-color: #ec664b;
        }
      }
    }
  }
}
.mask {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.65;
  z-index: 999;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4.2rem;
  .qr-code {
    width: 4rem;
    height: 4rem;
    background: #8d8d8d;
    border-radius: 0.04rem;
  }
  .qr-tips {
    margin-top: 0.4rem;
    font-size: 0.32rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 0.42rem;
    text-align: center;
  }
  .close-dialog {
    margin-top: 0.32rem;
    width: 0.48rem;
    height: 0.48rem;
    background-image: url('../../assets/imgs/close-dialog.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
