var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"config-container"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"back",on:{"click":_vm.goBack}},[_c('div',{staticClass:"back-icon"})]),_c('div',{staticClass:"title"},[_vm._v("发红包")])]),_c('div',{staticClass:"bag-config"},[_c('div',{staticClass:"bag-type"},[_vm._v(_vm._s(_vm.form.type === 'ordinary' ? '普通红包' : '拼手气红包'))]),_c('div',{staticClass:"bag-input",on:{"click":_vm.selectLuckyAmount}},[_c('div',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.form.type === 'ordinary' ? '单个金额' : '红包总金额'))]),_c('div',{staticClass:"input-box"},[(_vm.form.type === 'ordinary')?_c('Field',{ref:"amount-input",staticStyle:{"padding":"16px"},attrs:{"input-align":"right","type":"number","placeholder":"0.00","maxlength":"8","formatter":_vm.inputFormatter},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}):_c('div',{staticClass:"amount-num"},[_vm._v(_vm._s(_vm.toggleAmount))]),_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"counting"},[_vm._v("元")]),(_vm.form.type === 'lucky')?_c('div',{staticClass:"arrow-bottom"}):_vm._e()])],1)]),_c('div',{staticClass:"bag-tips"},[(_vm.form.type === 'ordinary')?_c('span',{staticClass:"tips-text"},[_vm._v("红包金额上限20000，至少1元 ")]):_vm._e(),_c('span',{staticClass:"tips-orange",on:{"click":_vm.switchRedType}},[_vm._v(_vm._s(_vm.form.type === 'ordinary' ? '改为拼手气红包' : '改为普通红包'))])]),_c('div',{staticClass:"bag-input num",on:{"click":function () {
          if (_vm.form.type === 'ordinary') { _vm.$refs['count-input'].focus(); }
        }}},[_c('div',{staticClass:"input-label"},[_vm._v("红包个数")]),_c('div',{staticClass:"input-box"},[(_vm.form.type === 'ordinary')?_c('Field',{ref:"count-input",staticStyle:{"padding":"0.4rem"},attrs:{"input-align":"right","type":"digit","placeholder":"填写个数","maxlength":"3","formatter":_vm.inputFormatterCount},model:{value:(_vm.form.count),callback:function ($$v) {_vm.$set(_vm.form, "count", $$v)},expression:"form.count"}}):_c('div',{staticClass:"amount-num"},[_vm._v(_vm._s(_vm.toggleNum))]),_vm._m(0)],1)]),_c('div',{staticClass:"tips-more"},[_c('div',{staticClass:"tips-text"},[_vm._v(_vm._s(_vm.form.type === 'ordinary' ? '红包个数上限500个' : ''))]),_c('div',{class:{
          more: true,
          on: _vm.isOpenConfig,
        },on:{"click":_vm.openConfig}},[_c('div',{staticClass:"more-text"},[_vm._v("更多设置")]),_c('div',{staticClass:"more-icon"})])]),_c('div',{class:{ 'more-config': true, on: _vm.isOpenConfig }},[_c('div',{staticClass:"bag-input num",on:{"click":function () {
            _vm.$refs['countdown-min'].focus();
          }}},[_c('div',{staticClass:"input-label"},[_vm._v("倒计时")]),_c('div',{staticClass:"input-box"},[_c('Field',{ref:"countdown-min",staticStyle:{"width":"3rem","padding":"0.4rem"},attrs:{"input-align":"right","type":"digit","placeholder":"","maxlength":"3","formatter":function (val) {
                if (val > 700) {
                  _vm.$toast('倒计时最多设置700分钟！');
                  return 700;
                }
                if (!val && !val.length) {
                  return '';
                }
                return Number(val);
              }},model:{value:(_vm.form.countdown_min),callback:function ($$v) {_vm.$set(_vm.form, "countdown_min", $$v)},expression:"form.countdown_min"}}),_vm._m(1)],1)]),_c('div',{staticClass:"bag-input",on:{"click":function () {
            _vm.$refs['level'].focus();
          }}},[_c('div',{staticClass:"input-label"},[_vm._v("角色等级")]),_c('div',{staticClass:"input-box"},[_c('div',{staticClass:"greater"}),_c('Field',{ref:"level",staticStyle:{"width":"0.9rem","padding":"0.4rem 0.08rem"},attrs:{"input-align":"right","type":"digit","placeholder":"1","maxlength":"4","formatter":function (val) {
                if (val > 1000) {
                  _vm.$toast('等级最高设置1000级！');
                  return 1000;
                }
                if (!val && !val.length) {
                  return '';
                }
                if (val < 1) {
                  _vm.$toast('等级最低设置1级！');
                  return 1;
                }
                return Number(val);
              }},model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}}),_vm._m(2)],1)]),_c('div',{staticClass:"bag-input",on:{"click":function () {
            _vm.$refs['create-role'].focus();
          }}},[_c('div',{staticClass:"input-label"},[_vm._v("首次创角时间")]),_c('div',{staticClass:"input-box"},[_c('Field',{ref:"create-role",staticStyle:{"width":"3rem","padding":"0.4rem"},attrs:{"input-align":"right","type":"digit","placeholder":"0","maxlength":"3","formatter":function (val) {
                if (val > 999) {
                  _vm.$toast('创角时间最长设置999小时！');
                  return 999;
                }
                if (!val && !val.length) {
                  return '';
                }
                return Number(val);
              }},model:{value:(_vm.form.first_role_create_hours),callback:function ($$v) {_vm.$set(_vm.form, "first_role_create_hours", $$v)},expression:"form.first_role_create_hours"}}),_vm._m(3)],1)]),_c('div',{staticClass:"create-tips"},[_vm._v("0小时为无创角时间限制")])]),_c('div',{staticClass:"total-money"},[_c('div',{staticClass:"symbol"},[_vm._v("¥")]),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.form.type === 'ordinary' ? _vm.ordinaryToggleAmount : _vm.toggleAmount || 0))])]),_c('div',{staticClass:"button-box"},[_c('div',{class:{
          button: true,
          on: (_vm.form.type === 'ordinary' && Number(_vm.form.amount) && Number(_vm.form.count)) || (_vm.form.type === 'lucky' && _vm.toggleAmount),
        },on:{"click":_vm.createRedBag}},[_vm._v(" 塞钱进红包 ")])])]),(_vm.isShowPay)?_c('div',{staticClass:"mask"}):_vm._e(),(_vm.isShowPay)?_c('div',{staticClass:"dialog"},[_c('img',{staticClass:"qr-code",attrs:{"src":_vm.qrCode}}),_c('div',{staticClass:"qr-tips"},[_vm._v("打开微信扫一扫，扫描二维码进行支付")]),_c('div',{staticClass:"close-dialog",on:{"click":_vm.closePayDialog}})]):_vm._e(),_c('Popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('Picker',{attrs:{"title":"","show-toolbar":"","columns":_vm.columns},on:{"confirm":_vm.onConfirm,"cancel":_vm.onCancel,"change":_vm.onChange}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-content"},[_c('div',{staticClass:"counting"},[_vm._v("个")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-content"},[_c('div',{staticClass:"counting"},[_vm._v("分钟")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-content"},[_c('div',{staticClass:"counting"},[_vm._v("级")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-content"},[_c('div',{staticClass:"counting"},[_vm._v("小时内")])])}]

export { render, staticRenderFns }